<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.monitoring_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
      <b-overlay>
        <b-row>
            <b-col xs="12" sm="12" md="7" lg="7" xl="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('globalTrans.from_date')"
                label-for="to_date"
                >
                <flat-pickr class="form-control"
                v-model="search.from_date"
                id="to_date"
                >
                </flat-pickr>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="7" lg="7" xl="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('globalTrans.to_date')"
                label-for="to_date"
                >
                <flat-pickr class="form-control"
                v-model="search.to_date"
                id="to_date"
                >
                </flat-pickr>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="7" lg="7" xl="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('research_manage.team_name')"
                label-for="team_id"
                >
                <b-form-select
                plain
                id="team_id"
                :options="monitoringTeamList"
                v-model="search.team_id"
                placeholder=""
                >
                <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
                </b-form-group>
            </b-col>
          </b-row>
          <b-row>
              <b-col xs="12" sm="12" md="3" lg="3" xl="2">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
          </b-row>
        </b-overlay>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.monitoring_report') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <!-- <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty> -->
                  <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                        <template v-slot:cell(from_date)="data">
                    {{ data.item.from_date | dateFormat }}
                    </template>
                    <template v-slot:cell(to_date)="data">
                    {{ data.item.to_date | dateFormat }}
                    </template>
                    <template v-slot:cell(schedule)="data">
                      <b-button class="mr-1" title="View Details" v-b-modal.modal-detail variant=" iq-bg-success mr-1" size="sm" @click="details(data.item)"><i class="fa fa-eye m-0 "></i></b-button>
                    </template>
                    <template v-slot:cell(status)="data">
                        <span v-if="data.item">{{ 'No Schedule Available' }}</span>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
     <b-modal id="modal-detail" size="xl" :title="`${$t('research_manage.monitoring_report')}`" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailModal :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import DetailModal from './DetailModal.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { monitoringReportListApi } from '../../../api/routes'
import RouteBasedList from '@/mixins/route-base-list'

export default {
  mixins: [RouteBasedList],
  components: {
    DetailModal
  },
  data () {
    return {
      editItemId: 0,
      search: {
        team_id: 0,
        org_id: 0,
        to_date: 0,
        from_date: 0,
        fiscal_year_id: 0,
        is_external: 1
      }
    }
  },
  computed: {
    orgList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    fiscalYearList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    monitoringTeamList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.monitoringTeamList.filter(item => item.status === 1)
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('research_manage.thematic_area'), class: 'text-left' },
          { label: this.$t('research_manage.project_title'), class: 'text-left' },
          { label: this.$t('research_manage.team_name'), class: 'text-left' },
          { label: this.$t('research_manage.monitoring_schedule'), class: 'text-center' }
          // { label: this.$t('globalTrans.status'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'thematic_name_bn' },
          { key: 'project.project_title_bn' },
          { key: 'team_name_bn' },
          { key: 'schedule' }
          // { key: 'status' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'thematic_name' },
          { key: 'project.project_title' },
          { key: 'team_name' },
          { key: 'schedule' }
          // { key: 'status' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
  //
  },
  mounted () {
    core.index()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, monitoringReportListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
          const tmpFiscal = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(type => type.value === item.fiscal_year_id)
          const tmpTeam = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.monitoringTeamList.find(type => type.value === item.monitoring_team_member.team_id)
          const thematicAreaObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(Item => Item.value === item.thematic_area_id)
          const tmpOrg = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(type => type.value === item.org_id)
          const newData = {
              thematic_name: thematicAreaObj !== undefined ? thematicAreaObj.text_en : '',
              thematic_name_bn: thematicAreaObj !== undefined ? thematicAreaObj.text_bn : '',
              fis_name: tmpFiscal !== undefined ? tmpFiscal.text_en : '',
              fis_name_bn: tmpFiscal !== undefined ? tmpFiscal.text_bn : '',
              team_name: tmpTeam !== undefined ? tmpTeam.text_en : '',
              team_name_bn: tmpTeam !== undefined ? tmpTeam.text_bn : '',
              org_name: tmpOrg !== undefined ? tmpOrg.text_en : '',
              org_name_bn: tmpOrg !== undefined ? tmpOrg.text_bn : ''
          }
          return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>
