import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat, mobileNumber } from '@/Utils/fliter'

function getProgress (mainItem, vm) {
  const gdata = []
  mainItem.forEach((item, index) => {
      if (item) {
        const date = dateFormat(item.created_at)
        const perc = vm.$n(item.progress_percentage)
        gdata.push({ text: vm.$t('globalTrans.date') + ' = ' + date + vm.$t('research_manage.progress') + ': ' + perc + '\n', style: 'td', alignment: 'left' })
      }
  })
  return gdata
}

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, proposal, vm) => {
    try {
        Store.commit('mutateCommonProperties', {
          loading: true
        })
        if (i18n.locale === 'bn') {
          pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        let pdfContent = []
        pdfContent = [
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        pdfContent.push({ text: '', style: 'fertilizer' })
        const topRows = [
          [
            { text: vm.$n(1) + '. ' + vm.$t('research_manage.project_info') + ' :', bold: true, style: 'th', colSpan: '4', alignment: 'left' },
            { },
            { },
            { }
          ],
          [
            { text: vm.$t('external_research.project_id'), bold: true, style: 'td' },
            { text: vm.proposal.project.proposal_auto_id, style: 'td', colSpan: '3' },
            { },
            { }
          ],
          [
            { text: vm.$t('research_manage.project_title'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.project.project_title : proposal.project.project_title_bn, style: 'td', colSpan: '3' },
            { },
            { }
          ],
          [
            { text: vm.$t('research_manage.thematic_area'), bold: true, style: 'td' },
            { text: vm.getThematicAreaList(proposal.thematic_area_id), style: 'td', colSpan: '3' },
            { },
            { }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '30%', '20%', '30%'],
            body: topRows
          }
        })
        const allRowsHead2 = [
          [
            { text: vm.$n(2) + '. ' + vm.$t('research_manage.objectives') + ' :', style: 'th', alignment: 'left', colSpan: '2' },
            { }
          ]
        ]
          proposal.project.description.details.map((item, index) => {
          allRowsHead2.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.specific_objective_bn : item.specific_objective, style: 'td', alignment: 'center' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '80%'],
            body: allRowsHead2
          }
        })
      if (proposal.project.locations.length > 0) {
        const allRowsHead3 = [
          [
            { text: vm.$n(3) + '. ' + vm.$t('external_research.implementation_location') + ' ' + vm.$t('sidebar.information') + ' :', style: 'th', alignment: 'left', colSpan: '3' },
            // { },
            { },
            { }
          ],
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            // { text: vm.$t('globalTrans.org_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.division_section'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.address'), style: 'th', alignment: 'center' }
          ]
        ]
        proposal.project.locations.map((item, index) => {
          allRowsHead3.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            // { text: vm.$i18n.locale === 'bn' ? item.org_name_bn : item.org_name, style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.division_bn : item.division, style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.address_bn : item.address, style: 'td', alignment: 'center' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '*', '*'],
            body: allRowsHead3
          }
        })
      }
      if (proposal.project.co_investigator.investigators) {
        const allRowsHead4 = [
          [
            { text: vm.$n(4) + '. ' + vm.$t('research_manage.designation_leader_address') + ' :', style: 'th', alignment: 'left', colSpan: '7' },
            { },
            { },
            { },
            { },
            { },
            { }
          ],
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('research_manage.org_or_univ_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.principal_investigator_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.address'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalUserData.mobile_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.email'), style: 'th', alignment: 'center' }
          ]
        ]
          proposal.project.co_investigator.investigators.map((item, index) => {
          allRowsHead4.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: vm.getComponentOrgName(item.org_type, item.component_org_id), style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.investigator_name_bn : item.investigator_name, style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.designation_bn : item.designation, style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.address_bn : item.address, style: 'td', alignment: 'center' },
            { text: mobileNumber(item.mobile_no), style: 'td', alignment: 'center' },
            { text: item.email, style: 'td', alignment: 'center' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '15%', '15%', '15%', '15%', '15%', '15%'],
            body: allRowsHead4
          }
        })
      }
      if (proposal.project.activities.length > 0) {
        const allRowsHead5 = [
          [
            { text: vm.$n(5) + '. ' + vm.$t('research_manage.major_activity') + ' :', style: 'th', alignment: 'left', colSpan: '5' },
            { },
            { },
            { },
            { }
          ],
          [
            { text: vm.$t('external_research.activity_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.activity_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('research_manage.duration'), style: 'th', alignment: 'center' },
            { text: vm.$t('research_manage.proposed_progress') + '(%)', style: 'th', alignment: 'center' },
            { text: vm.$t('research_manage.actual_progress') + '(%)', style: 'th', alignment: 'center' }
          ]
        ]
        proposal.project.activities.map((item, index) => {
          allRowsHead5.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.activity_name_bn : item.activity_name, style: 'td', alignment: 'center' },
            { text: dateFormat(item.start_date) + '-' + dateFormat(item.end_date), style: 'td', alignment: 'center' },
            { text: getProgress(item.docs.filter(el => el.is_external === 1), vm), style: 'td', alignment: 'center' },
            { text: getProgress(item.docs.filter(el => el.is_external === 0), vm), style: 'td', alignment: 'center' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '15%', '15%', '*', '*'],
            body: allRowsHead5
          }
        })
      }
      if (proposal.project.budget.details) {
        const allRowsHead6 = [
          [
            { text: vm.$n(6) + '. ' + vm.$t('external_research.budget_info'), style: 'th', alignment: 'left', colSpan: '7' },
            { },
            { },
            { },
            { },
            { },
            { }
          ],
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('research_manage.budget_type'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.budget_head'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.code'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.expense_year'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.budget_percentage'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.allocation_amount') + ' (' + vm.$t('globalTrans.taka') + ')', style: 'th', alignment: 'center' }
          ]
        ]
        proposal.project.budget.details.map((item, index) => {
          allRowsHead6.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: vm.getBudgetTypeName(item.budget_type_id), style: 'td', alignment: 'center' },
            { text: vm.getBudgetName(item), style: 'td', alignment: 'center' },
            { text: vm.$n(item.code, { useGrouping: false }), style: 'td', alignment: 'center' },
            { text: vm.getExpenseYearName(item.expense_year), style: 'td', alignment: 'center' },
            { text: vm.$n(item.budget_percentage), style: 'td', alignment: 'center' },
            { text: Number.isInteger(parseFloat(item.allocation_amount)) ? (vm.$n(item.allocation_amount) + (i18n.locale === 'bn' ? '.০০' : '.00')) : vm.$n(item.allocation_amount), style: 'td', alignment: 'center' }
          ])
        })

        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '*', '*', '*', '*', '*', '*'],
            body: allRowsHead6
          }
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '*', '*', '*', '*', '*', '*'],
            body: [
              [
                { text: vm.$t('external_research.target_budget') + '' + vm.$t('globalTrans.taka') + ' = ' + vm.$n(proposal.project.budget.target_budget), style: 'th', alignment: 'middle', colSpan: 3 },
                { },
                { },
                { text: vm.$t('external_research.total_budget') + '' + vm.$t('globalTrans.taka') + '\n' + vm.$t('external_research.total_budget') + ' (%)', style: 'td', alignment: 'right', colSpan: 3 },
                { },
                { },
                { text: (Number.isInteger(parseFloat(proposal.project.budget.total_budget)) ? (vm.$n(proposal.project.budget.total_budget) + (i18n.locale === 'bn' ? '.০০' : '.00')) : vm.$n(proposal.project.budget.total_budget)) + '\n' + vm.$n(proposal.project.budget.total_budget_perc), style: 'td', alignment: 'center' }
              ]
            ]
          }
        })
      }
      if (proposal.project.activities.length > 0) {
        const allRowsHead7 = [
          [
            { text: vm.$n(7) + '. ' + vm.$t('research_manage.constraints_suggestions') + ' :', style: 'th', alignment: 'left', colSpan: '2' },
            { }
          ],
          [
            { text: vm.$t('research_manage.constraints'), style: 'th', alignment: 'center' },
            { text: vm.$t('research_manage.suggestions'), style: 'th', alignment: 'center' }
          ]
        ]
        proposal.project.activities.map((item, index) => {
          allRowsHead7.push(
          [
            { text: item.constraints, style: 'td', alignment: 'left' },
            { text: item.suggestions, style: 'td', alignment: 'left' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: allRowsHead7
          }
        })
      }
      if (proposal.project.activities.length > 0) {
        const allRowsHead8 = [
          [
            { text: vm.$n(8) + '. ' + vm.$t('research_manage.any_other_information') + ' :', style: 'th', alignment: 'left' }
          ]
        ]
        proposal.project.activities.map((item, index) => {
          allRowsHead8.push(
          [
            { text: item.other_information, style: 'td', alignment: 'left' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['*'],
            body: allRowsHead8
          }
        })
      }
      const allRowsHead9 = [
        [
          { text: vm.$n(9) + '. ' + vm.$t('research_manage.name_signature_interviewed') + ' :' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n' + '\n', style: 'th', alignment: 'left' }
        ]
      ]
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['*'],
          body: allRowsHead9
        }
      })
        const allRowsHead10 = [
          [
            { text: vm.$n(10) + '. ' + vm.$t('research_manage.sign_monitoring_team') + ' :', style: 'th', alignment: 'left', colSpan: '2' },
            { }
          ],
          [
            { text: vm.getOrgName(proposal.monitoring_team_member.org_id) + '\n' + vm.getFiscalYearList(proposal.monitoring_team_member.fiscal_year_id) + '\n' + vm.getMonitoringTeam(proposal.monitoring_team_member.team_id), style: 'td', alignment: 'left', colSpan: '2' },
            { }
          ]
        ]
        proposal.monitoring_team_member.members.map((item, index) => {
          allRowsHead10.push(
          [
            { text: vm.getTeamMember(item.user_id) + '\n' + vm.getRoleName(item.master_role_id) + '\n' + vm.getDesignationName(item.designation_id) + '\n' + mobileNumber(item.mobile_no) + '\n' + vm.getOrganization(item.org_id), style: 'td', alignment: 'left' },
            { }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: allRowsHead10
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'portrait',
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            bold: true,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 18,
              bold: true,
              margin: [0, -10, 0, -20]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
      // pdfMake.createPdf(docDefinition, null, null, null).download('research-project-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
